<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk2Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk2',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '招标信息池-电煤招标详情'

    getBulk2Details({id: this.$route.params.id}).then(res => {
      this.tableData = [
        {text: '项目名称', value: res[0].title},
        {text: '装卸港', value: res[0].loadPort + '-' + res[0].unloadPort},
        {text: '货量', value: res[0].weight},
        {text: '受载日期', value: res[0].loadDate},
        {text: '装卸天数', value: res[0].loadDay},
        {text: '滞期费', value: res[0].expense},
        {text: '截止时间', value: res[0].endDate},
        {text: '备注', value: res[0].remarks},
        {text: '公告内容', value: res[0].notice}
      ]

    })

  },
  methods: {}
}
</script>

<style scoped>

</style>
